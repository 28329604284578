import React, { useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Layout from "../../components/Layout"
import HeroHeader from "../../components/HeroHeader"
import HeroImage from "../../images/aerospace.jpg"
import SEO from "../../components/SEO"
import ApplicationQuote from "../../components/ApplicationQuotes"
import AerospaceImages from "../../components/applicationImageComponents/aerospaceImages"

const PageBody = styled.main`
  max-width: 1000px;
  margin: 0 auto;

  h1 {
    font-size: 2.5em;
  }
`

const Modal = styled.div`
  position: fixed;
  width: 900px;
  height: 600px;
  bottom: 50%;
  margin-bottom: -300px;
  left: 50%;
  margin-left: -450px;
  background-color: green;
  animation: fadeIn ease 0.5s;
  z-index: 3;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 910px) {
    width: 750px;
    height: 500px;
    margin-bottom: -250px;
    margin-left: -375px;
  }

  @media (max-width: 775px) {
    width: 600px;
    height: 400px;
    margin-bottom: -200px;
    margin-left: -300px;
  }

  @media (max-width: 625px) {
    width: 450px;
    height: 300px;
    margin-bottom: -150px;
    margin-left: -225px;
  }

  @media (max-width: 475px) {
    width: 350px;
    height: 233px;
    margin-bottom: -116.5px;
    margin-left: -175px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  i {
    position: absolute;
    top: 20px;
    right: 20px;
    /* z-index: 2; */
    color: red;
    font-size: 2em;
    cursor: pointer;
  }

  button {
    background-color: transparent;
    padding: 0.5rem;
    border: 1px solid black;
    border-radius: 10px;
    cursor: pointer;
    transition-duration: 0.3s;

    &:hover {
      background-color: red;
      color: white;
    }
  }

  #machine-btn {
    position: absolute;
    bottom: 30px;
    left: 30px;
  }

  #application-btn {
    position: absolute;
    bottom: 30px;
    left: 145px;
  }
`

const StyledImageGalleryHeader = styled.h2`
  max-width: 1400px;
  margin: 1rem auto;
  border-bottom: 2px solid red;
`

const StyledImageGallery = styled.div`
  max-width: 1400px;
  display: flex;
  flex-flow: row wrap;
  margin: 1rem auto;
  justify-content: space-between;
`

const Thumbnail = styled.div`
  position: relative;
  max-width: 330px;
  max-height: 330px;
  overflow: hidden;
  cursor: pointer;
  margin: 10px 0;

  i {
    position: absolute;
    top: 10px;
    right: 20px;
    color: white;
    font-size: 1.4em;
    /* z-index: 3; */
    pointer-events: none;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;

    &:hover {
      color: red;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay {
    display: none;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    bottom: 0;
    color: white;
    animation: slideIn ease 0.5s;
    pointer-events: none;

    @keyframes slideIn {
      0% {
        margin-bottom: -150px;
      }
      100% {
        margin-bottom: 0;
      }
    }

    h4 {
      margin-left: 15px;
    }

    a {
      text-decoration: none;
      color: white;
      pointer-events: auto;
      &:hover {
        color: red;
      }
    }
  }

  &:hover {
    .overlay {
      display: block;
    }
  }
`

const BackButton = styled.button`
  margin: 2rem;
  font-size: 1em;
  border: none;
  padding: 1rem;
  background-color: red;
  color: white;
  cursor: pointer;
`

const AerospacePage = () => {
  // return <div>aerospace</div>
  const [modal, setModal] = useState({
    display: "none",
    name: "",
  })
  const [machineImage, setMachineImage] = useState({})
  const [machineLink, setMachineLink] = useState({})

  const [model, setModel] = useState({
    name: "",
  })

  function handleImageClick(e) {
    setModal({
      display: "block",
      name: e.target.dataset.model,
    })
    setMachineImage(e.target.src)
    setMachineLink(e.target.dataset.link)
    setModel({ name: e.target.dataset.model })
  }

  return (
    <>
      <SEO title="Aerospace" description="" />

      <Layout>
        <HeroHeader
          heroImage={HeroImage}
          // title="Warehouse Application"
          // subHeading="Keep Your Unique Warehouse Clean with Our Specialized Products."
          buttonTitle="See More"
          showButton="none"
        />
        <PageBody>
          <h1>Aerospace</h1>
          <p>
            Aerospace is a technologically advanced industry, that produces
            aircraft, space vehicles, missiles, aircraft engines and other
            associated components, certainly more so than the simple scrubbers
            and sweepers that we build. While much of this industry is geared
            toward government work, most of the companies involved have high
            housekeeping standards and utilize sweepers and scrubbers to
            maintain their areas.
          </p>
          <h2>Maintain Your Work Area:</h2>
          <ul>
            <li>
              The world's leading producer of large and small jet engines uses
              Factory Cat scrubbers to maintain some of their testing and
              assembly areas.
            </li>
            <li>
              The Factory Cat scrubbers have the ability to clean up any spilled
              fluids off the high gloss floors, leaving them dry and ready to
              use.
            </li>
          </ul>
          <ApplicationQuote quote="High levels of solvents and some heavy metals, with the wide open clean out tank, stainless steel components and simplicity of design made it easy to manage the contaminants for safe removal and make it simple for the operator. - JC" />
          <ApplicationQuote quote="Riders have a lot of tank capacity and battery run-time on a small machine footprint.  This provided productivity while still being able to fit into production areas where machining stations are close together.  P7, double-siped drive tire for excellent traction / control of machine around expensive equipment and products. - KB" />
          <ApplicationQuote quote="The high-performance cleaning capability that FactoryCat scrubbers offer is unparalleled in the floor machine market and ideal for the Aerospace market.  Aerospace facilities typically have high gloss epoxy, ESD (electrostatic discharge) flooring, or polished concrete floors that require precise cleaning abilities. - JM" />
        </PageBody>

        <AerospaceImages />
        <Link to="/applications">
          <BackButton>All Applications</BackButton>
        </Link>
      </Layout>
    </>
  )
}

export default AerospacePage
